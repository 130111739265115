import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../Services/auth.service';

@Component({
    selector: 'app-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class AppLoginComponent implements OnInit {

    message = '';
    data: any;

    loginFormGroup;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        const token = localStorage.getItem('JWT_TOKEN');
        const active = localStorage.getItem('USR_ACTIVE');

        if (token && active === 'true') {
            this.router.navigate([{ outlets : { primary: 'dashboard', dashboard: 'projects'} }]);
        }

        this.loginFormGroup = new FormGroup({
            emailLoginCtrl: new FormControl('', Validators.compose([Validators.required, Validators.email])),
            passwordLoginCtrl: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[\\S]+$')]))
        });
    }

    login(data) {
        const body = {
            email: data.emailLoginCtrl,
            password: data.passwordLoginCtrl
        };

        this.authService.signIn(body).then((response) => {
            this.router.navigate([{ outlets : { primary: 'dashboard', dashboard: 'projects'} }]);
        }).catch(err => {
            this.message = err.error.error;
        });
    }
}
