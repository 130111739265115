import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { AppErrorComponent } from './Error/error.component';
import { AppOutletErrorComponent } from './Error/outlet.error.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/marketplace',
        pathMatch: 'full',
    },
    {
        path: 'error',
        component: AppErrorComponent,
    },
    {
        path: '**',
        component: AppErrorComponent,
    },
    {
        outlet: 'dashboard',
        path: '**',
        component: AppOutletErrorComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes/*, { enableTracing: true }*/)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
