import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppRestService } from '../../../Services/rest.service';
import { AuthService } from '../../../Services/auth.service';

@Component({
    selector: 'app-auth-confirmed-mail',
    templateUrl: './mail.confirmed.component.html',
    styleUrls: ['./mail.confirmed.component.scss']
})
export class MailConfirmedComponent implements OnInit {

    message = '';
    confirmationToken: any;
    isConfirmed = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private restService: AppRestService,
        private authService: AuthService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.confirmationToken = this.route.snapshot.paramMap.get('token');

        if (!this.confirmationToken) { this.router.navigate(['auth/confirm']); }

        this.authService.activateUser(this.confirmationToken).then((response) => {
            const { confirmation } = response;
            this.isConfirmed = confirmation;
        }).catch(err => {
            this.isConfirmed = err.confirmation;
            if (err.error.status === 400) {
                this.router.navigate(['auth/confirm']);
            } else if (err.error.status === 422) {
                this.message = err.error.message;
            }
        });
    }
}
