import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

import { AppRestService } from './rest.service';
import { AuthService } from './auth.service';


@Injectable()
export class UserService {

    constructor(
        private http: HttpClient,
        private restService: AppRestService,
        private authService: AuthService
    ) {}

    isEmailRegistered(email: string) {
        const body = { email };

        return new Promise((resolve, reject) => {
            this.restService.post('users/checkEmail', body).subscribe(() => {
                resolve(null);
            }, () => { resolve({ 'isEmailUnique': true}); });
        });
    }

    currentUserIsAdminOfAProject(project: any) {
        const currentUser = this.authService.getLoggedInUser();
        if (!currentUser) { return false; }

        for (const userLink of project.UserProjects) {
            if (userLink.UserId === currentUser.id && userLink.admin) {
                return true;
            }
        }

        return false;
    }

    getNbProject(shared: boolean = false) {
        const user = this.authService.getLoggedInUser();
    }
}
