import { Component, OnInit } from '@angular/core';
import { AppRestService } from '../../Services/rest.service';

interface Notification {
    image;
    message;
    url;
}

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class AppNotificationComponent implements OnInit {

    notifications: Notification[];
    toggleAction = true;
    newNotifications = false;
    isInvitation = true;

    constructor (
        private restService: AppRestService,
    ) {
    }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications() {
        this.restService.get('notifications/list').subscribe(data => {
            console.log(data);
            this.notifications = data.notifications;
            this.newNotifications = this.notifications.length >= 1;
        }, error => {
            console.error(error);
        });
    }

    toggleActionClick(event) {
        this.toggleAction = !this.toggleAction;
    }

    accept(notification) {
        console.log(event);
        this.restService.patch('projects/' + notification.projectId + '/collaborators/confirm', {})
            .subscribe(data => {
                console.log(data);
            }, error => {
                console.error(error);
        });
    }

    decline(notification) {
        console.log(event);
        this.restService.patch('projects/' + notification.projectId + '/collaborators/decline', {})
            .subscribe(data => {
                console.log(data);
            }, error => {
                console.error(error);
            });
    }

    archive(event) {
        this.decline(event);
        console.log(event);
    }
}
