import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { AppAuthRoutingModule } from './auth-routing.module';

// Components
import { AppLoginComponent } from './Login/login.component';
import { AppSignupComponent } from './Signup/signup.component';
import { AppMaterialModule } from '../material.module';
import { MailConfirmationComponent } from './MailConfirmation/AskConfirmation/mail.confirmation.component';
import { MailConfirmedComponent } from './MailConfirmation/MailConfirmed/mail.confirmed.component';

// Providers
import { UserService } from '../Services/user.service';

@NgModule({
    declarations: [
        AppLoginComponent,
        AppSignupComponent,
        MailConfirmationComponent,
        MailConfirmedComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppAuthRoutingModule,
        AppMaterialModule,
    ],
    providers: [ UserService ]
})
export class AppAuthModule {}
