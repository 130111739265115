import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '../../Services/user.service';
import { AuthService } from '../../Services/auth.service';


@Component({
    selector: 'app-auth-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class AppSignupComponent implements OnInit {

    message = '';
    data: any;
    signUpForm;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        const token = localStorage.getItem('JWT_TOKEN');
        if (token) {
            this.router.navigate(['dashboard']);
        }

        this.signUpForm = new FormGroup({
            firstNameLoginCtrl: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[\\S]+$')])),
            lastNameLoginCtrl: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[\\S]+$')])),
            emailSignUpCtrl: new FormControl('', Validators.compose([Validators.required, Validators.email]),
                this.isEmailUnique.bind(this)),
            passwordSignUpCtrl: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[\\S]+$')])),
            confirmPasswordSignUpCtrl: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[\\S]+$')]))
        }, {validators: this.checkSignUpPasswords});
    }

    signUp(data) {
        const body = {
            first: data.firstNameLoginCtrl,
            last: data.lastNameLoginCtrl,
            email: data.emailSignUpCtrl,
            password: data.passwordSignUpCtrl
        };

      this.authService.register(body).then((response) => {
            this.router.navigate(['auth/confirm']);
        }).catch(err => {
            this.message = err.error.msg;
        });
    }

    isEmailUnique(control: FormControl) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.userService.isEmailRegistered(control.value).then((data) => {
                    resolve(data);
                });
            }, 1500);
        });
    }

    checkSignUpPasswords: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
        const passwordSignUpCtrl = control.get('passwordSignUpCtrl').value;
        const confirmPasswordSignUpCtrl = control.get('confirmPasswordSignUpCtrl').value;

        let err = null;

        this.message = '';

        if (passwordSignUpCtrl !== confirmPasswordSignUpCtrl) {
            err = 'Password not matching';
            this.message = 'The passwords must be identicals';
        }
        return err ? err.toString() : null;
    }

}


