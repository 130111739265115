import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { AppMaterialModule } from '../material.module';
import { AppDashboardRoutingModule } from './dashboard-routing.module';
import { UploadModule } from './Upload/upload.module';
import { AudioStreamingModule } from './AudioStreaming/audio.streaming.module';
import { Ng2DropdownModule } from 'ng2-material-dropdown';

// Components
import { AppDashboardComponent } from './dashboard.component';
import { AppNotificationComponent } from './Notification/notification.component';
import { AppProjectsComponent } from './projects.component';
import { AppAssetsComponent } from './assets.component';
import { AppSharedComponent } from './shared.component';
import { AppListComponent } from './List/list.component';
import { AppProjectDialogComponent } from './List/ProjectDialog/project-dialog.component';
import { AppSearchBarComponent } from './SearchBar/searchbar.component';

@NgModule({
    declarations: [
        AppDashboardComponent,
        AppNotificationComponent,
        AppProjectsComponent,
        AppAssetsComponent,
        AppSharedComponent,
        AppListComponent,
        AppProjectDialogComponent,
        AppSearchBarComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        Ng2DropdownModule,
        AppMaterialModule,
        AppDashboardRoutingModule,
        UploadModule,
        ReactiveFormsModule,
        FormsModule,
        AudioStreamingModule
    ],
    exports: [
        AppListComponent,
        AppProjectDialogComponent,
        AppSearchBarComponent
    ],
    entryComponents: [
        AppProjectDialogComponent
    ],
    providers: [ DatePipe ]
})
export class AppDashboardModule {}
