import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestService } from '../Services/rest.service';
import {AuthService} from '../Services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class AppDashboardComponent implements OnInit {

    email = '';
    first = '';
    last = '';
    projectsNbr = 0;
    assetsNbr = 0;
    spaceLeft = 0.3;
    totalSpace = 2;
    imgUrl: string = null;
    tabsNav: object[] = [
        { name: 'PROJECTS', url: 'projects' },
        { name: 'ASSETS', url: 'assets' },
        { name: 'SHARED WITH ME', url: 'shared' },
    ];
    activeTab = '';

    constructor (
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private restService: AppRestService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.restService.get('users/').subscribe(data => {
            console.log(data);
            const { user } = data;
            if (user) {
                console.log(user);
                this.first = user.first;
                this.last = user.last;
                this.email = user.email;
            }
            console.log(this.first, this.last, this.email);
        }, error => {
            if (error.status === 401) {
                this.logout();
            }
        });

        this.getProjects();
        this.getAssets();
    }

    getProjects() {
        this.restService.get('projects').subscribe(data => {
            console.log(data);
            this.projectsNbr = data.projects.length;
        }, error => {
            console.error(error);
        });

        this.restService.get('projects/shared').subscribe(data => {
            console.log(data);
            this.projectsNbr += data.projects.length;
        }, error => {
            console.error(error);
        });
    }

    getAssets() {
        this.restService.get('projects/assets').subscribe(data => {
            console.log(data);
            this.assetsNbr = data.projects.length;
        }, error => {
            console.error(error);
        });
    }

    navigate(url: string) {
        this.activeTab = url;
        this.router.navigate(
            [{ outlets: { primary: ['dashboard'], dashboard: [url] } }],
            { relativeTo: this.route.root }
            );
    }

    logout() {
        this.authService.signOut();
        this.router.navigate(
            [{ outlets: { primary: ['marketplace'], dashboard: null } }],
            { relativeTo: this.route.root }
        );
    }

}
