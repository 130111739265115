import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarRef, MatSnackBarConfig, SimpleSnackBar } from '@angular/material';

/*
@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
*/

class AlertData {
    message: string;
    action: string;
    config: MatSnackBarConfig;

    constructor(message: string, type?: string) {
        this.message = message;
        this.action = 'DISMISS';
        this.config = new MatSnackBarConfig();
        this.config.duration = 2000;
        this.config.verticalPosition = 'top';
        this.config.horizontalPosition = 'center';
        if (type === 'success') {
            this.config.panelClass = 'alert-success';
        } else if (type === 'error') {
            this.config.panelClass = 'alert-error';
        }
    }
}

@Injectable()
export class AppAlertService {

    private _alertQueue: AlertData[] = [];
    private _snackBarRef: MatSnackBarRef<SimpleSnackBar>;
    private _isSnackBarVisible: boolean = false;

    constructor(
        private snackBar: MatSnackBar
    ) {}

    private _showNextSnackBar() {
        if (this._alertQueue.length === 0) {
            return;
        }

        this._isSnackBarVisible = true;
        const alertData = this._alertQueue.shift();

        this._snackBarRef = this.snackBar.open(alertData.message, alertData.action, alertData.config);

        this._snackBarRef.afterDismissed().subscribe(() => {
            this._isSnackBarVisible = false;
            this._showNextSnackBar();
        });
    }

    private _addAlertToQueue(alertData: AlertData) {
        this._alertQueue.push(alertData);
        if (!this._isSnackBarVisible) {
            this._showNextSnackBar();
        }
    }

    success(message: string) {
        const alertData = new AlertData(message, 'success');

        this._addAlertToQueue(alertData);
    }

    error(message: string) {
        const alertData = new AlertData(message, 'error');

        this._addAlertToQueue(alertData);
    }
}
