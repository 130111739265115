import { Injectable } from '@angular/core';
import { AppRestService } from './rest.service';
import { Router } from '@angular/router';

@Injectable()
export class AppAudioService {

    private source: any = null;
    private context: AudioContext = new AudioContext();
    private pausedAt = 0;
    private startedAt = 0;
    private buffer: any = null;
    private playing = false;
    private loaded = false;
    private hasPlayed = false;

    constructor(
        private router: Router,
        private restService: AppRestService,
    ) {}

    private process(data: any) {
        let arrayBuffer;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            arrayBuffer = fileReader.result;

            this.source = this.context.createBufferSource(); // Create Sound Source
            this.context.decodeAudioData(arrayBuffer, (buffer) => {
                this.buffer = buffer;
                this.source.buffer = buffer;
                this.source.connect(this.context.destination);
                this.startedAt = this.context.currentTime;
            });
        };

        this.loaded = true;
        fileReader.readAsArrayBuffer(data);
    }

    public loadFile(fileId: number, needAuth: boolean) {
        console.log(fileId);
        const requestPrefix = needAuth ? '' : 'marketplace';

        this.restService.getFile(`files/${requestPrefix}/${fileId}/download`, needAuth).subscribe(data => {
            console.log(data);

            this.process(data);
        }, error => {
            console.error(error);
        });
    }

    public playTune() {
        const offset = this.pausedAt;

        this.source = this.context.createBufferSource();
        this.source.connect(this.context.destination);
        this.source.buffer = this.buffer;
        this.source.start(0, offset);

        this.startedAt = this.context.currentTime - offset;
        this.playing = true;
        this.hasPlayed = true;
    }

    public pauseTune() {
        const elapsed = this.context.currentTime - this.startedAt;
        this.source.stop();
        this.pausedAt = elapsed;
        this.playing = false;
    }

    public stopTune() {
        if (this.source) {
            this.source.disconnect();
            this.source.stop(0);
            this.source = null;
        }
        this.pausedAt = 0;
        this.context = new AudioContext();
        this.playing = false;
    }

    public getPlaying() {
        return this.playing;
    }

    public getCurrentTime() {
        if (!this.playing) {
            // console.info('PAUSED: ', this.pausedAt);
            return this.pausedAt;
        } else {
            if (this.hasPlayed) {
                // console.info('PLAYED: ', this.context.currentTime - this.startedAt);
                return this.context.currentTime - this.startedAt;
            }
        }
        return 0;
    }

    public getDuration() {
        return this.buffer ? this.buffer.duration : null;
    }

    public unloadFile() {
        this.source = null;
        this.buffer = null;
        this.loaded = false;
        this.playing = false;
        this.pausedAt = 0;
    }

    public audioIsLoaded() {
        return this.loaded;
    }
}
