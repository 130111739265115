import {Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogUploadComponent } from './DialogUpload/dialog.upload.component';
import { UploadService } from '../../Services/upload.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
    @Input() buttonType: string;

    constructor(
        public dialog: MatDialog,
        public uploadService: UploadService
    ) {}

    public openUploadDialog() {
        const dialogRef = this.dialog.open(DialogUploadComponent, { panelClass: 'upload-dialog-container', width: '95%', height: '95%' });
        // Temporary fix to reload projects list upon upload
        dialogRef.afterClosed().subscribe(() => {
            this.uploadService.needUpdate();
        });
        //
    }
}
