import { Injectable } from '@angular/core';
import { AppRestService } from './rest.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {

    activated = false;
    currentUser: any = null;

    constructor(
        private http: HttpClient,
        private restService: AppRestService,
        private router: Router
    ) {
        console.log('RELOAD', this.currentUser);
        if (!this.currentUser) {
            this.restService.get('users/').subscribe(data => {
                const { user } = data;
                this.currentUser = user;
                this.activated = this.currentUser.active;
                localStorage.setItem('USR_ACTIVE', `${this.activated}`);
            }, error => {
                if (error.status === 401) {
                    this.signOut();
                    this.redirectToLogin();
                }
            });
        }
    }


    signIn(body: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.restService.post('users/login', body)
                .subscribe(response => {
                    this.currentUser = response;
                    this.activated = this.currentUser.active;
                    localStorage.setItem('JWT_TOKEN', this.currentUser.token);

                    if (this.activated === true) {
                        localStorage.setItem('USR_ACTIVE', 'true');
                    } else {
                        localStorage.setItem('USR_ACTIVE', 'false');
                    }
                    resolve(this.currentUser);
                }, error => {
                    console.error('An error occurred', error);
                    reject(error);
            });
        });
    }

    register(body: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.restService.post('users/register', body)
                .subscribe(response => {

                    this.currentUser = response;
                    localStorage.setItem('JWT_TOKEN', this.currentUser.token);
                    localStorage.setItem('USR_ACTIVE', 'false');
                    this.activated = this.currentUser.active;

                    resolve(this.currentUser);
                }, error => {
                    console.error('An error occurred', error);
                    reject(error);
                });
        });
    }

    activateUser(confirmationToken: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.restService.patch('users/verify/' + confirmationToken, {})
                .subscribe(data => {
                    localStorage['USR_ACTIVE'] = 'true';
                    this.activated = true;
                    resolve({ confirmation: true });
                }, error => {
                    this.activated = false;
                    reject({ confirmation: false, error });
                });
        });
    }

    /* signInAnonymously(): firebase.Promise<any> {
        return this.afAuth.auth.signInAnonymously()
            .catch(error => console.log('ERROR @ AuthService#signInAnonymously() :', error));
    }

    signInWithGithub(): firebase.Promise<any> {
        return this.signIn(new firebase.auth.GithubAuthProvider());
    }

    signInWithGoogle(): firebase.Promise<any> {
        return this.signIn(new firebase.auth.GoogleAuthProvider());
    }

    signInWithTwitter(): firebase.Promise<any> {
        return this.signIn(new firebase.auth.TwitterAuthProvider());
    }

    signInWithFacebook(): firebase.Promise<any> {
        return this.signIn(new firebase.auth.FacebookAuthProvider());
    }

    signInWithEmail(userEmail: string, userPassword: string): firebase.Promise<any> {
        return this.afAuth.auth.signInWithEmailAndPassword(userEmail, userPassword);
    }

    createUserWithEmail(userEmail: string, userPassword: string): firebase.Promise<any> {
        return this.afAuth.auth.createUserWithEmailAndPassword(userEmail, userPassword);
    }

    updateEmail(userEmail: string): firebase.Promise<any> {
        return this.afAuth.auth.currentUser.updateEmail(userEmail);
    }

    updatePassword(userPassword: string): firebase.Promise<any> {
        return this.afAuth.auth.currentUser.updatePassword(userPassword);
    }

    sendPasswordResetEmail(userEmail: string): firebase.Promise<any> {
        return this.afAuth.auth.sendPasswordResetEmail(userEmail);
    } */

    // Sign out
    signOut(): void {
        localStorage.removeItem('JWT_TOKEN');
        localStorage.removeItem('USR_ACTIVE');
        this.currentUser = null;
        this.activated = false;
    }

    redirectToLogin(): void {
        this.router.navigate(['login']);
    }

    // Get session
    getLoggedInUser() {
        return this.currentUser;
    }

    // Get auth user activation
    getActivatedUser() {
        return this.activated;
    }
}
