import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../../material.module';
import { AudioStreamingComponent } from './audio.streaming.component';
import { AppAudioService } from '../../Services/audio.service';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppMaterialModule
    ],
    declarations: [
        AudioStreamingComponent,
    ],
    exports: [AudioStreamingComponent],
    providers: [AppAudioService]
})
export class AudioStreamingModule {}
