import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';


// Modules
import { AppMaterialModule } from '../material.module';
import { AppMarketPlaceRoutingModule } from './marketplace-routing.module';
import { AudioStreamingModule } from '../Dashboard/AudioStreaming/audio.streaming.module';
import { AppDashboardModule } from '../Dashboard/dashboard.module';

// Components
import { AppMarketPlaceComponent } from './marketplace.component';

@NgModule({
    declarations: [
        AppMarketPlaceComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        AppMaterialModule,
        AppMarketPlaceRoutingModule,
        ReactiveFormsModule,
        AudioStreamingModule,
        AppDashboardModule
    ],
    /*entryComponents: [
        AppProjectDialogComponent
    ],*/
    providers: [ DatePipe ]
})
export class AppMarketPlaceModule {}
