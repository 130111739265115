import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { DialogUploadComponent } from './DialogUpload/dialog.upload.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { UploadService } from '../../Services/upload.service';
import { AppMaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileDropModule } from 'ngx-file-drop';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FileDropModule,
    ],
    declarations: [
        UploadComponent,
        DialogUploadComponent
    ],
    exports: [
        UploadComponent
    ],
    entryComponents: [DialogUploadComponent], // Add the DialogComponent as entry component
    providers: [UploadService]
})
export class UploadModule {}
