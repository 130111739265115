import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


// Modules
import { AppMaterialModule } from '../material.module';

// Components
import { AppOutletErrorComponent } from './outlet.error.component';
import { AppErrorComponent } from './error.component';

@NgModule({
    declarations: [
        AppOutletErrorComponent,
        AppErrorComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        AppMaterialModule,
        RouterModule
    ],
    providers: [ DatePipe ]
})
export class AppErrorModule {}
