import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss']
})
export class AppSearchBarComponent implements OnChanges {

    @Input() list: any[];
    @Output() listChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() propertiesList: string[][];
    listSave: any[];
    searchBarGroup = new FormGroup({
        searchBarControl: new FormControl('')
    });

    constructor() {}

    ngOnChanges() {
        console.log('OnChanges');
        if (isNullOrUndefined(this.listSave)) {
            console.log('> Changed');
            this.listSave = this.list;
        }
    }

    handleMultipleProperties(object: object, properties: string[]): any {
        let len: number;

        len = properties.length;
        for (let i = 0; i < len; i++) {
            if (isNullOrUndefined(object[properties[i]])) {
                return null;
            }
            object = object[properties[i]];
        }
        return object;
    }

    matchingSearch(object: object) {
        const toCompareList: string[] = [];
        let result = false;

        this.propertiesList.forEach(properties => {
            const stringObject = this.handleMultipleProperties(object, properties);
            if (stringObject) {
                toCompareList.push(stringObject);
            }
        });
        toCompareList.forEach(toCompare => {
            const comparision = toCompare.toLowerCase().indexOf(this.searchBarGroup.controls.searchBarControl.value.toLowerCase()) >= 0
                || this.searchBarGroup.controls.searchBarControl.value.toLowerCase().indexOf(toCompare.toLowerCase()) >= 0;
            result = result || comparision;
        });
        return (result);
    }

    search() {
        const searchedList: any[] = [];

        if (this.searchBarGroup.controls.searchBarControl.value.length === 0) {
            this.resetSearch();
            return;
        }
        this.list = this.listSave;
        this.list.forEach(item => {
            if (this.matchingSearch(item)) {
                searchedList.push(item);
            }
        });
        this.listChange.emit(searchedList);
    }

    resetSearch() {
        this.searchBarGroup.controls.searchBarControl.reset();
        this.list = this.listSave;
        this.listChange.emit(this.listSave);
    }
}
