import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppRestService } from '../Services/rest.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private restService: AppRestService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = localStorage.getItem('JWT_TOKEN');

        const activeUser = localStorage.getItem('USR_ACTIVE');

        if (!currentUser && !activeUser) { this.router.navigate(['/auth/login']); }

        if (activeUser !== 'true') { this.router.navigate(['/auth/confirm']); }

        return true;
    }
}
