import { NgModule } from '@angular/core';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule
} from '@angular/material';


@NgModule({
    imports: [
        MatButtonModule,
        MatListModule,
        MatTabsModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatStepperModule,
        MatDialogModule,
        MatGridListModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTableModule
    ],
    exports: [
        MatButtonModule,
        MatListModule,
        MatTabsModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCardModule,
        MatIconModule,
        MatStepperModule,
        MatDialogModule,
        MatGridListModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTableModule
    ]
})
export class AppMaterialModule {}
