import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AppAudioService } from '../../Services/audio.service';

@Component({
    selector: 'app-audio-streaming',
    templateUrl: './audio.streaming.component.html',
    styleUrls: ['./audio.streaming.component.scss']
})

export class AudioStreamingComponent implements OnInit, OnDestroy {

    @Input() fileId: number;
    @Input() needAuth: boolean;

    value = 0;
    elapsed = 0;
    duration = 0;
    calculing = false;

    constructor(
        public audioService: AppAudioService
    ) {}

    ngOnInit() {
        this.audioService.loadFile(this.fileId, this.needAuth);
    }

    play() {
        this.audioService.playTune();
    }

    pause() {
        this.audioService.pauseTune();
    }

    stop() {
        this.audioService.stopTune();
    }

    isLoaded() {
        return this.audioService.audioIsLoaded();
    }

    isPlaying() {
        return this.audioService.getPlaying();
    }

    getPercent() {
        if (!this.audioService) {
            return;
        }
        const elapsed = this.audioService.getCurrentTime();
        const duration = this.audioService.getDuration();
        return elapsed / duration * 100;
    }

    setPercent() {
        this.value = this.getPercent();
        this.calculing = false;
    }

    updateTimeProgress() {
        if (this.calculing === false) {
            this.calculing = true;
            setTimeout(() => this.setPercent(), 125);
        }

        return this.value;
    }

    ngOnDestroy() {
        if (this.audioService.getPlaying()) {
            this.audioService.stopTune();
        }

        this.audioService.unloadFile();
    }
}
