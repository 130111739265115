import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AppRestService } from '../../../Services/rest.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';

export interface ProjectDialogData {
    id: number;
    userIsAdmin: any;
    isRouteMarketplace: boolean;
    published: boolean;
    uuid: string;
    name: string;
    type: string;
    creationDate: Date;
    lastUpdateDate: Date;
    image: string;
    description: string;
}

export interface PropertyDisplay {
    name: string;
    value: number;
}

@Component({
    selector: 'app-project-dialog',
    templateUrl: 'project-dialog.component.html',
    styleUrls: ['project-dialog.component.scss']
})
export class AppProjectDialogComponent implements OnInit {

    file: any;
    preview = false;
    audioExts = ['mp3', 'wav', 'flac', 'ogg'];
    imgUrl: string = null;
    imgBaseUrl = '/assets/img/logo-media-';
    date: string = null;
    actionButtonLabel = 'Push to blockchain';
    context = 'blockchain';
    actionButton: any;
    collaboration = false;
    isDownloading = false;
    signature: any = null;
    users: any[];
    selectedUsers: any[];
    propertyTable: PropertyDisplay[] = [];
    displayedColumns: string[] = ['name', 'value'];
    excludeProperty: string[] = ['createdAt', 'updatedAt', 'creators', 'contributors', 'state', 'identifier'];

    constructor (
        private router: Router,
        private restService: AppRestService,
        public dialogRef: MatDialogRef<AppProjectDialogComponent>,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public project: ProjectDialogData
    ) {
        if (project.uuid && project.uuid !== '') {
            this.actionButton = this.pushToMarketplace;
            this.context = 'marketplace';
        } else {
            this.actionButton = this.pushToBlockchain;
            this.context = 'blockchain';
        }

        console.log('MARKETPLACE', this.project.isRouteMarketplace);
        this.actionButtonLabel = `Push to ${this.context}`;
    }

    ngOnInit() {
        this.getFile();
        if (!this.project.isRouteMarketplace) { this.getUsers(); } else { this.getCollaborators(); }
    }

    private setPropertyTable() {
        this.file.fingerprint = this.project.uuid;
        for (const key in this.file) {
            if (key !== null) {
                const value = this.file[key];
                if (!this.excludeProperty.includes(key) && value !== 'null') {
                    this.propertyTable.push({name: key, value});
                    console.log('ADD PROPERTY: ', this.propertyTable);
                }
            }
        }
    }

    getCollaborators() {
        this.restService.get(`projects/${this.project.id}/collaborators`, !this.project.isRouteMarketplace).subscribe(data => {
            const { userProjects } = data;
            if (userProjects !== null && userProjects.length !== 0) {
                this.users = userProjects;
                console.log('USERS:', userProjects);
            } else {
                this.users = null;
            }
        }, error => {
            this.users = null;
            console.error(error);
        });
    }

    getFile() {
        const request = this.project.isRouteMarketplace ? 'marketplace' : 'list';
        this.restService.get(`files/${request}/${this.project.id}`, !this.project.isRouteMarketplace).subscribe(data => {
            console.log('RETRIEVE FILE: ', data);
            this.file = data.file;

            this.setPropertyTable();
            if (data.file.datatype) {
                this.project.type = data.file.datatype;
                this.imgUrl = this.imgBaseUrl + data.file.datatype + '.svg';
            } else {
                this.imgUrl = this.imgBaseUrl + 'unknown.svg';
            }

            this.date = this.datePipe.transform(this.file.createdAt, 'MM/dd/yyyy');
        }, error => {
            this.imgUrl = this.imgBaseUrl + 'unknown.svg';
            console.error(error);
        });
    }

    getUsers() {
        this.restService.get('users/all').subscribe(data => {
            console.log(data);
            if (data && data.users !== null && data.users.length !== 0) {
                this.users = data.users;
            } else {
                this.users = null;
            }
        }, error => {
            this.users = null;
            console.error(error);
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

    isAudio(): boolean {
        return this.audioExts.includes(this.file.datatype);
    }

    askPreview() {
        this.preview = true;
    }

    isAsked(): boolean {
        return this.preview;
    }

    downloadFile() {
        this.isDownloading = true;
        this.restService.getFile('files/' + this.file.id + '/download').subscribe(data => {
            console.log(data);

            FileSaver.saveAs(data, this.file.name);
            this.isDownloading = false;
        });
    }

    collaborationSwitch() {
        this.collaboration = !this.collaboration;
        this.selectedUsers = [];
    }

    collaborateWith(admin: boolean = false) {
        const collaborators = []; // Put users already collaborating

        this.selectedUsers.forEach(user => {
            if (!collaborators.includes(user)) {
                console.log('USER', user);
                this.restService.post('projects/' + this.project.id + '/collaborators', { email: user.email, admin }).subscribe(data => {
                    console.log(data);
                    this.dialogRef.close();
                }, error => {
                    console.error(error);
                });
            }
        });
        collaborators.forEach(user => {
            if (!this.selectedUsers.includes(user)) {
                this.restService.delete('projects/' + this.project.id + '/collaborators/' + user.id).subscribe(data => {
                    console.log(data);
                    this.dialogRef.close();
                }, error => {
                    console.error(error);
                });
            }
        });
    }

    pushToMarketplace(): void {
        console.log('GETTING PROOF IS HANDLED NOW');
        const body = {};

        this.restService.patch('projects/' + this.project.id + '/publish', body).subscribe(data => {
            console.log(data);
            this.dialogRef.close();
        }, error => {
            console.error(error);
        });
    }

    pushToBlockchain(): void {
        console.log('PUSH TO BLOCKCHAIN HANDLED NOW');
        const body = {};

        this.restService.patch('projects/' + this.project.id + '/push', body).subscribe(data => {
            console.log(data);
            this.dialogRef.close();
        }, error => {
            console.error(error);
        });
    }

    getSignatureFromBlockchain(): void {
        console.log('GET SIGNATURE BLOCKCHAIN HANDLED NOW');

        this.restService.get('projects/' + this.project.id + '/signature').subscribe(data => {
            console.log(data);
            const { signature } = data;
            this.signature = signature;
        }, error => {
            console.error(error);
        });
    }

    canPushToBlockchain(): boolean {
        console.info('CAN PUSH TO BLOCKCHAIN:', this.project.userIsAdmin, this.file !== null);
        return this.project.userIsAdmin && this.file !== null;
    }

    hadRight(): boolean {
        if (this.context === 'blockchain') {
            return this.canPushToBlockchain();
        } else if (this.context === 'marketplace') {
            return this.project.userIsAdmin && !this.project.published;
        }
        return this.project.userIsAdmin;
    }
}
