import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { AppSignupComponent } from './Signup/signup.component';
import { AppLoginComponent } from './Login/login.component';
import { MailConfirmationComponent } from './MailConfirmation/AskConfirmation/mail.confirmation.component';
import { MailConfirmedComponent } from './MailConfirmation/MailConfirmed/mail.confirmed.component';

const routes: Routes = [
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                component: AppLoginComponent
            },
            {
                path: 'signup',
                component: AppSignupComponent
            },
            {
                path: 'confirm',
                component: MailConfirmationComponent
            },
            {
                path: 'confirm/:token',
                component: MailConfirmedComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppAuthRoutingModule {}
