import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialogRef, MatStepper } from '@angular/material';
import { HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { AppRestService } from '../../../Services/rest.service';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadEvent, UploadFile, FileSystemFileEntry } from 'ngx-file-drop';
import { throwError } from 'rxjs';
import { catchError, map, tap, last } from 'rxjs/operators';
import { AppAlertService } from '../../../Services/alert.service';

@Component({
    selector: 'app-dialog-upload',
    templateUrl: './dialog.upload.component.html',
    styleUrls: ['./dialog.upload.component.scss']
})
export class DialogUploadComponent {
    @ViewChild('file') file;
    @ViewChild('stepper') private myStepper: MatStepper;

    public fileUploaded: File;

    // data Project
    project: any;

    // data File
    dataType: string = null;
    fileName: string = null;

    fileContent: any = null;

    // User feedback
    fileTypeAccepted = ['mp3', 'wav', 'flac', 'ogg'];
    uploadRes: any = null;
    initiateUpload = '';
    progressPercent: number;
    primaryButtonText = 'Create';
    uploadMessage = 'Nice job ! You can save your project now !';
    isUploading = false;
    isSaving = false;
    showCancelButton = true;
    uploadState: string = null;
    stepperFinished = false;

    // Form Group
    pickFileGroup = this.fb.group({
        file: [null, Validators.required]
    });
    aboutFileGroup = this.fb.group({
        nameFileCtrl: ['', Validators.required],
        descFileCtrl: [],
        typeFileCtrl: ['' , [Validators.required]],
        tagsFileCtrl: [],
    });
    advInfoGroup = this.fb.group({
        registrationNameCtrl: [],
        supInstFileCtrl: [],
        maxOccFileCtrl: [],
        rightsFileCtrl: []
    });

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private dialogRef: MatDialogRef<DialogUploadComponent>,
        public restService: AppRestService,
        private alertService: AppAlertService
    ) {}

    handleFile(event) {
        this.isUploading = true;
        const reader = new FileReader();

        if (event.files && event.files.length) {
            const [fileUploaded] = event.files;

            this.fileName = fileUploaded.relativePath;
            if (fileUploaded.fileEntry.isFile) {
                const fileEntry = fileUploaded.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    this.fileUploaded = file;

                    this.dataType = this.extractFileExtension(fileUploaded.relativePath);

                    if (!this.fileTypeAccepted.includes(this.dataType)) {
                        console.error(`This type : ${this.dataType} is not supported`);
                        this.isUploading = false;
                        return false;
                    }

                    // Here you can access the real file
                    // console.log('HandleFile ', fileUploaded);
                    // console.log('Real File', fileUploaded.relativePath, file);

                    reader.readAsText(file);

                    reader.onload = () => {
                        this.pickFileGroup.patchValue({
                            file: reader.result
                        });

                        // console.log(reader.result);
                        this.fileContent = reader.result;
                        // need to run CD since file load runs outside of zone
                        this.cd.markForCheck();

                        this.isUploading = false;

                        this.aboutFileGroup.get('typeFileCtrl').setValue(this.dataType);
                    };
                });
            } else {
                console.error(`This type : ${fileUploaded.relativePath} is a directory, please enter a file`);
                this.alertService.error(`This type : ${fileUploaded.relativePath} is a directory, please enter a file`);
            }
        }
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
        this.stepperFinished = this.myStepper._getFocusIndex() === this.myStepper._steps.length;
    }
    goForward(stepper: MatStepper) {
        stepper.next();
        this.stepperFinished = this.myStepper._getFocusIndex() === this.myStepper._steps.length;
    }

    private extractFileExtension(filename) {
        const rule = /(?:\.([^.]+))?$/;
        const result = rule.exec(filename);

        return result.length === 2 ? result[1] : null;
    }

    private getEventMessage(event: HttpEvent<any>, formData) {
        console.log('EVENT: ', event);
        switch (event.type) {

            case HttpEventType.Sent:
                return this.fileSent(event, formData.get('name'));

            case HttpEventType.UploadProgress:
                return this.fileUploadProgress(event);

            case HttpEventType.Response:
                return this.apiResponse(event);

            default:
                return `File "${formData.get('name')}" surprising upload event: ${event.type}.`;
        }
    }

    private sizeOf(bytes) {
        if (bytes === 0) { return '0.00 B'; }
        const e = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
    }


    private fileSent(event, name) {
        const percentDone = Math.round((100 * event.loaded) / event.total);
        const fileSize = this.sizeOf(this.fileUploaded.size);
        this.initiateUpload = `Uploading file "${name}" of size : ${fileSize}.`;
        return { status: 'sent', message: percentDone };
    }

    private fileUploadProgress(event) {
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return { status: 'progress', message: percentDone };
    }

    private apiResponse(event) {
        event.body.status = 'success';
        return event.body;
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened. Please try again later.');
    }


    uploadAction() {
        const body = {
            name: this.aboutFileGroup.get('nameFileCtrl').value
        };

        this.restService.post('projects', body).subscribe( projectResponse => {
            this.project = projectResponse.project;

            if (this.project) {
                const id = this.project.id;
                const formData: FormData = new FormData();
                formData.append('file', this.fileUploaded, this.fileName);
                formData.append('name', this.fileName);
                formData.append('datatype', this.aboutFileGroup.get('typeFileCtrl').value);
                formData.append('assetDescription', this.aboutFileGroup.get('descFileCtrl').value);
                formData.append('tags', this.aboutFileGroup.get('tagsFileCtrl').value);
                formData.append('maximumOccurences', this.advInfoGroup.get('maxOccFileCtrl').value);
                formData.append('supportingInstitution', this.advInfoGroup.get('supInstFileCtrl').value);
                formData.append('rights', this.advInfoGroup.get('rightsFileCtrl').value);
                formData.append('registrationAuthority', this.advInfoGroup.get('registrationNameCtrl').value);

                this.isSaving = true;
                this.restService.uploadFile('files/' + id, formData).pipe(
                    map(event => this.getEventMessage(event, formData)),
                    catchError(this.handleError)
                ).subscribe(
                    (res) => {
                        console.log(res);
                        this.uploadRes = res;
                        switch (this.uploadRes.status) {
                            case ('progress') :
                                this.progressPercent = this.uploadRes.message;
                                this.uploadState = 'Uploading content ...';
                                break;
                            case ('success') :
                                console.log('Upload finished', event);
                                this.alertService.success('Your file has been uploaded');
                                this.uploadState = 'Upload finished !';
                                this.isSaving = false;
                                this.uploadMessage = 'Hurray your project has been created';
                                this.dialogRef.close();
                                break;
                        }
                    },
                    (err) => {
                        console.error('UPLOAD ERROR: ', err);
                        this.alertService.error('Upload failed');
                        this.isSaving = false;
                        this.uploadMessage = 'Upload failed, try again?';
                    }
                );
            }
        });

        this.uploadState = 'success';
    }
}
