import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../Services/auth.service';
import { AppRestService } from '../../../Services/rest.service';

@Component({
    selector: 'app-auth-confirm-mail',
    templateUrl: './mail.confirmation.component.html',
    styleUrls: ['./mail.confirmation.component.scss']
})
export class MailConfirmationComponent implements OnInit {

    message = '';
    first = '';
    email = '';

    constructor(
        private http: HttpClient,
        private router: Router,
        private restService: AppRestService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        const { user } = this.authService.getLoggedInUser();

        if (!user) {
            this.authService.signOut();
            this.router.navigate(['auth/signup']);
        }

        if (user.active === true) {
            console.log('user activated');
            localStorage.setItem('USR_ACTIVE', 'true');
            this.router.navigate([{ outlets : { primary: 'dashboard', dashboard: 'projects'} }]);
        }

        if (user) {
            console.log(user);
            this.first = user.first;
            this.email = user.email;
        }
    }

    resendConfirmation () {
        if (this.email === '') { return; }

        this.restService.post('users/resendToken', {email: this.email}).subscribe(data => {
            console.log(data);
        }, error => {
            console.error('ERROR RESEND', error);
            localStorage.removeItem('JWT_TOKEN');
            this.router.navigate(['auth/login']);
        });
    }
}
