import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRestService } from '../Services/rest.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss']
})
export class AppMarketPlaceComponent {

    tabsNav: object[] = [
        { name: 'ASSETS', url: 'assets' }
    ];

    constructor (
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    authenticate() {
        localStorage.removeItem('JWT_TOKEN');
        this.router.navigate(
            [{ outlets: { primary: ['auth', 'login'], dashboard: null } }],
            { relativeTo: this.route.root }
        );
    }

    register() {
        localStorage.removeItem('JWT_TOKEN');
        this.router.navigate(
            [{ outlets: { primary: ['auth', 'signup'], dashboard: null } }],
            { relativeTo: this.route.root }
        );
    }
}
