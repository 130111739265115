import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRestService } from '../../Services/rest.service';
import { MatDialog } from '@angular/material';
import { AppProjectDialogComponent } from './ProjectDialog/project-dialog.component';
import { UploadService } from '../../Services/upload.service';
import { UserService } from '../../Services/user.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class AppListComponent implements OnInit {

    @Input() projectCategory: string;

    projects: any[];
    recentProjects: any[] = [];
    data: any;

    constructor (
        private router: Router,
        private restService: AppRestService,
        private userService: UserService,
        public dialog: MatDialog,
        private uploadService: UploadService
    ) {
        // Temporary fix to reload projects list upon upload
        this.uploadService.toUpdate.subscribe(toUpdate => {
            if (toUpdate === true) {
                this.getProjects();
                this.uploadService.updated();
            }
        });
        //
    }

    ngOnInit() {
        this.getProjects();
    }

    getProjects() {
        const needAuth = !(this.projectCategory === '/marketplace');

        this.restService.get('projects' + this.projectCategory, needAuth).subscribe(data => {
            console.log(data);
            this.projects = data.projects;
        }, error => {
            console.error(error);
        });
        if (this.projectCategory !== '/marketplace') {
            this.restService.get('projects' + this.projectCategory + '/recents').subscribe(data => {
                console.log(data);
                this.recentProjects = data.projects;
            }, error => {
                console.error(error);
            });
        }
    }

    openDialog(id: number): void {
        let project = this.projects.find(item => item.id === id);
        if (project.Project) { project = project.Project; }

        const dialogRef = this.dialog.open(AppProjectDialogComponent, {
            panelClass: 'project-info-dialog',
            width: '60%',
            height: '65%',
            autoFocus: false,
            data: {
                id: project.id,
                name: project.name,
                userIsAdmin: this.userService.currentUserIsAdminOfAProject(project),
                isRouteMarketplace: this.projectCategory === '/marketplace',
                published: project.published,
                uuid: project.uuid,
                type: 'TYPE NOT HANDLED',
                creationDate: project.createdAt,
                lastUpdateDate: project.updatedAt,
                description: 'DESCRIPTION NOT HANDLED'
            }
        });
        // Temporary fix to reload projects list upon push to blockchain
        dialogRef.afterClosed().subscribe(() => {
            this.getProjects();
        });
        //

    }

    openEditDialog(id: number): void {
        console.log('EDIT MODE NOT HANDLED');
    }

    isAuthorizedToEdit(projectId: number): boolean {
        if (!this.projects) { return false; }
        if (this.projectCategory !== '') { return false; }
        let project = this.projects.find(x => x.id === projectId);

        if (project !== null && project.Project !== null) { project = project.Project; }

        return project.uuid === null;
    }
}
