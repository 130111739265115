import { Component } from '@angular/core';
import { AuthService } from './Services/auth.service';

@Component({
    selector: 'app-root',
    providers: [ AuthService ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Spectrum';
}
