import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AppRestService {
    requestPath = 'http://51.75.122.93:3000/api/';
    httpOptions: any = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    };

    constructor(
        private http: HttpClient
    ) {}

    private dataExtractor(response: Response) {
        const body: any = response;
        console.log(response);
        return body || {};
    }

    tokenHandler() {
        const token = localStorage.getItem('JWT_TOKEN');
        if (token) {
            this.httpOptions.headers.append('Authorization', token);
        } else {
            delete this.httpOptions.headers['Authorization'];
        }
    }

    get(request: string, auth: boolean = true): Observable<any> {
        const url: string = this.requestPath + request;
        const token = localStorage.getItem('JWT_TOKEN');

        if (auth) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token
                })
            };
            return this.http.get(url, httpOptions).pipe(
                map(this.dataExtractor)
            );
        } else {
            return this.http.get(url).pipe(
                map(this.dataExtractor)
            );
        }
    }

    getFile(request: string, auth: boolean = true): Observable<any> {
        const url: string = this.requestPath + request;
        const token = localStorage.getItem('JWT_TOKEN');
        let httpOptions = {};
        if (auth) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': token
                }),
                responseType: 'blob' as 'json'
            };
        } else {
            httpOptions = {
                responseType: 'blob' as 'json'
            };
        }

        return this.http.get<Blob>(url, httpOptions);
    }

    post(request: string, body: any): Observable<any> {
        const url: string = this.requestPath + request;
        const token: string = localStorage.getItem('JWT_TOKEN');
        let httpOptions: any;

        if (token) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': token
                })
            };
        } else {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                })
            };
        }

        return this.http.post<any>(url, JSON.stringify(body), httpOptions);
    }

    uploadFile(request: string, body: FormData): Observable<any> {
        const url: string = this.requestPath + request;
        const token = localStorage.getItem('JWT_TOKEN');

        return this.http.request('POST', url, {
                body,
                headers: new HttpHeaders({
                    'Authorization': token,
                    'Accept': 'multipart/form-data',
                }),
                reportProgress: true,
                observe: 'events'
            }
        );
    }

    put(request: string, body: any): Observable<any> {
        const url: string = this.requestPath + request;
        const token: string = localStorage.getItem('JWT_TOKEN');
        let httpOptions: any;

        if (token) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': token
                })
            };
        } else {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                })
            };
        }

        return this.http.put<any>(url, JSON.stringify(body), httpOptions);
    }

    patch(request: string, body: any): Observable<any> {
        const url: string = this.requestPath + request;
        const token: string = localStorage.getItem('JWT_TOKEN');
        let httpOptions: any;

        if (token) {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': token
                })
            };
        } else {
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json; charset=utf-8',
                })
            };
        }

        return this.http.patch<any>(url, JSON.stringify(body), httpOptions);
    }

    delete(request: string): Observable<any> {
        const url: string = this.requestPath + request;

        this.tokenHandler();
        return this.http.delete<any>(url);
    }

}

