import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppMarketPlaceModule } from './MarketPlace/marketplace.module';
import { AppAuthModule } from './Auth/auth.module';
import { AppDashboardModule } from './Dashboard/dashboard.module';
import { FileDropModule } from 'ngx-file-drop';
import { UploadModule } from './Dashboard/Upload/upload.module';
import { AppErrorModule } from './Error/error.module';

// Components
import { AppComponent } from './app.component';

// Services
import { AppRestService } from './Services/rest.service';
import { AppAlertService } from './Services/alert.service';
import { AuthService } from './Services/auth.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppMarketPlaceModule,
        AppAuthModule,
        AppDashboardModule,
        AppRoutingModule,
        FileDropModule,
        UploadModule,
        AppErrorModule
    ],
    providers: [
        AppRestService,
        AuthService,
        AppAlertService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
