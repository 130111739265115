import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { AppMarketPlaceComponent } from './marketplace.component';

const routes: Routes = [
    {
        path: 'marketplace',
        component: AppMarketPlaceComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppMarketPlaceRoutingModule {}
