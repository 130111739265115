import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { AppRestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class UploadService {

    project: any;

    constructor(
        private http: HttpClient,
        private restService: AppRestService
    ) {}

    // Temporary fix to reload projects list upon upload
    toUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    needUpdate() {
        this.toUpdate.next(true);
    }
    updated() {
        this.toUpdate.next(false);
    }
    //

    public upload(file: File): {[key: string]: Observable<number>} {

        const status = {};

        const body = {
            name: 'NewProject'
        };

        const progress = new Subject<number>();

        // send the http-request and subscribe for progress-updates

        status[file.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        return status;
    }
}