import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guard
import { AuthGuard } from '../_guards/auth.guard';

// Components
import { AppDashboardComponent } from './dashboard.component';
import { AppProjectsComponent } from './projects.component';
import { AppAssetsComponent } from './assets.component';
import { AppSharedComponent } from './shared.component';

const routes: Routes = [
    {
        path: 'dashboard',
        component: AppDashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        outlet: 'dashboard',
        path: 'projects',
        component: AppProjectsComponent,
        canActivate: [AuthGuard]
    },
    {
        outlet: 'dashboard',
        path: 'assets',
        component: AppAssetsComponent,
        canActivate: [AuthGuard]
    },
    {
        outlet: 'dashboard',
        path: 'shared',
        component: AppSharedComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppDashboardRoutingModule {}
