import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-error',
    template: ''
})
export class AppOutletErrorComponent {

    constructor (
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.handleError();
    }

    handleError() {
        this.router.navigate(
            [{ outlets: { primary: 'error', dashboard: null } }],
            { relativeTo: this.route.root }
        );
    }

}
